import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Prerequisites`}</h2>
    <ul>
      <li parentName="ul">{`A user with access to Engagement Studio;`}</li>
      <li parentName="ul">{`The smart windows added to Settings - Global – Administration - Product Management;  `}</li>
      <li parentName="ul">{`Already completed the Campaigns course;`}</li>
      <li parentName="ul">{`Contact window already created (with the desired visitor information fields);`}</li>
      <li parentName="ul">{`At least a voice skill created; `}</li>
      <li parentName="ul">{`Have an active E-Commerce App and create the desired Context (as explained in the Apps course).`}</li>
    </ul>
    <h2>{`Overview`}</h2>
    <p>{`Smart windows offer e-commerce functionality in an extended version. It makes it easier to:`}</p>
    <ul>
      <li parentName="ul">{`Recommend the perfect products in real-time `}</li>
      <li parentName="ul">{`Guide the customer through the shopping process, from product search to check out, all in a single channel `}</li>
      <li parentName="ul">{`Deliver a personalized shopping experience and increase customer satisfaction `}</li>
    </ul>
    <h2>{`Steps`}</h2>
    <h3>{`1. Add campaign`}</h3>
    <ol>
      <li parentName="ol">{`On the top left corner of Engagement Studio, click `}<em parentName="li">{`AdCenter`}</em>{` (`}<img parentName="li" {...{
          "src": "../images/ccas-icon-app.png",
          "alt": "icon-app"
        }}></img>{`
)`}</li>
    </ol>
    <p><img parentName="p" {...{
        "src": "../images/ccas-desktop.png",
        "alt": "ccas-desktop"
      }}></img>{`
2. Click `}<em parentName="p">{`Campaigns`}</em>{` on the menu.`}</p>
    <p><img parentName="p" {...{
        "src": "../images/ccas-applications.png",
        "alt": "ccas-applications"
      }}></img>{`
3. Click `}<em parentName="p">{`Add`}</em>{` to open a new Campaign.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9e640e031871779b1f6dd619d333233f/bb056/ec-add-campaign.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAACS0lEQVQoz4XR/UtTURzH8fsfRCgVKj1rkoIapgkRkihYQRAEQQRBIBb1oz2g9OAPItkDVNSydG5T0PSuvE7cZv1gqTnnSgnWNTcL+ik15ja33T3cvcOraEHRB158z4Fzzg+fI+SUlLO5pof0un7SayU21IhsvCySelVk0zWRLbUiaXUiGddFtt4ws+2WmR31ZnbWm8lu6KWgqY/Ce/3k3+6luLEbIW1vMdWmEUyOWXRDMs3DX2h570E/5qVtzIvB4cUw7sW4xoPJ6aFjwkv7+AydLi9dH79invrGJXEUITWzgG7HDCtR+W8SsbVlXAkTCYchmdT2XZNehIzcA4jO1QeTidWjyX9LxLWpxhPaTESjxBRFu9Uz5UHI2l9KVfs7dCMzPBxy82RYRjci83RU5tl7medjMi0OmdbxafTOZTIG17TG6JIxfZimzfUZvdPNFesowvbcIrJudpPdIJHT2Etek8S+uxKF9yWKHkiUPJI4+FjikE6itLmPshYL5a0WKvR9VBr7OWKyaI62rxBSM/MxjLgJRRTm/Ev4QhGNPxIloMTWBKMx/BGFhcASi+EIfiXKD98i8/4AixGFeELFOOlGSNmVh3litUM18Vtff48aj4OqoiZUrb9QSCEaXfkoq2cWIWV3Hq1vPzHv8/N9wcdcIPSH+eC6hWVL636Gwpq5YJBgKESX242QtqeA4juvOKwbpEw3QEXzAJUtVo61WTlutHGiw8bJThunXtg4Ldo489LOWcnOOYudqgE71TY7FwbtXHxj5/xrG78A0wOGSy6/ESYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/9e640e031871779b1f6dd619d333233f/e93cc/ec-add-campaign.webp 300w", "/static/9e640e031871779b1f6dd619d333233f/b0544/ec-add-campaign.webp 600w", "/static/9e640e031871779b1f6dd619d333233f/68fc1/ec-add-campaign.webp 1200w", "/static/9e640e031871779b1f6dd619d333233f/a2303/ec-add-campaign.webp 1800w", "/static/9e640e031871779b1f6dd619d333233f/4293a/ec-add-campaign.webp 2400w", "/static/9e640e031871779b1f6dd619d333233f/cb4c3/ec-add-campaign.webp 2962w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/9e640e031871779b1f6dd619d333233f/eed55/ec-add-campaign.png 300w", "/static/9e640e031871779b1f6dd619d333233f/7491f/ec-add-campaign.png 600w", "/static/9e640e031871779b1f6dd619d333233f/8537d/ec-add-campaign.png 1200w", "/static/9e640e031871779b1f6dd619d333233f/d2cc9/ec-add-campaign.png 1800w", "/static/9e640e031871779b1f6dd619d333233f/00711/ec-add-campaign.png 2400w", "/static/9e640e031871779b1f6dd619d333233f/bb056/ec-add-campaign.png 2962w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/9e640e031871779b1f6dd619d333233f/8537d/ec-add-campaign.png",
              "alt": "ec-add-campaign",
              "title": "ec-add-campaign",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
4. Fill in the name and select `}<em parentName="p">{`Onsite`}</em>{` campaign.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/aa72e7e07448e12adbe4ed4d9d1a0072/ebd60/ec-onsite-new-filled-in.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAABbUlEQVQoz52Sz0sCQRTH97/t3rFz1yIqoVIhhegUGJZoEERQnTrUqcAfmeFSaunuzuz83G/MrOumKx188GHm7Xz3+94Mz1lb30C+fIr9fAm5YjmmUMpS/IdCCYfHJ9jc2oVTqTVgQgphEZxh1RiORnAuajWbUBqCMQ7GGKSUEEKAcYEg5CCMI+QyAxMKUimrN9HrfcBpNOIOtdazSlEUwZtM8O0F6H8O8eMThEKDcgUqDPE+CAV0FFm9Cdd1U0OllD1IDk2nBkKo/UkpDaVTpNIIAmL1STP9fn+5oRGYKyt7HWHzaIFEkzGs1+tzhsn1Pc+zHfq+b3Oto+maQinNGl4t6dA8ssEIzfdFowTOuS0kpEoNK9XLeGymBiYIIRiPx2Cc2719vxlJAQVKydzYuMbwrFpbaea4AmQEtEchdm5aOHjo4bn5DmfvqIivwQCtdgedbjfmbUr3z7rAS7NjuXt6xfb5PXL1R1Svb/EL6GY5MYQ9YnwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/aa72e7e07448e12adbe4ed4d9d1a0072/e93cc/ec-onsite-new-filled-in.webp 300w", "/static/aa72e7e07448e12adbe4ed4d9d1a0072/b0544/ec-onsite-new-filled-in.webp 600w", "/static/aa72e7e07448e12adbe4ed4d9d1a0072/68fc1/ec-onsite-new-filled-in.webp 1200w", "/static/aa72e7e07448e12adbe4ed4d9d1a0072/480e2/ec-onsite-new-filled-in.webp 1322w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/aa72e7e07448e12adbe4ed4d9d1a0072/eed55/ec-onsite-new-filled-in.png 300w", "/static/aa72e7e07448e12adbe4ed4d9d1a0072/7491f/ec-onsite-new-filled-in.png 600w", "/static/aa72e7e07448e12adbe4ed4d9d1a0072/8537d/ec-onsite-new-filled-in.png 1200w", "/static/aa72e7e07448e12adbe4ed4d9d1a0072/ebd60/ec-onsite-new-filled-in.png 1322w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/aa72e7e07448e12adbe4ed4d9d1a0072/8537d/ec-onsite-new-filled-in.png",
              "alt": "ec-onsite-new-filled-in",
              "title": "ec-onsite-new-filled-in",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Name`}</em>{`: It is required  and it is free text but you should give a meaningful name.`}</li>
      <li parentName="ul"><em parentName="li">{`Type`}</em>{`: Select `}<em parentName="li">{`Onsite`}</em>{` or `}<em parentName="li">{`Landing page`}</em>{` or `}<em parentName="li">{`A/B testing`}</em>{` or `}<em parentName="li">{`Offsite`}</em>{`. This field defines the type of campaign.`}</li>
      <li parentName="ul"><em parentName="li">{`Channel`}</em>{`: Select `}<em parentName="li">{`Default`}</em>{` or create a new one to add.`}</li>
      <li parentName="ul"><em parentName="li">{`Contact list`}</em>{`: You can choose an existing contact list, but it's not mandatory.`}</li>
    </ul>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`On the left bottom corner of form `}<em parentName="li">{`New campaign`}</em>{`, click button `}<em parentName="li">{`Accept`}</em>{`.`}</li>
    </ol>
    <h2>{`Result`}</h2>
    <p>{`After accepting, `}<em parentName="p">{`Edit campaign`}</em>{` is open.`}</p>
    <h3>{`2. Add a widget`}</h3>
    <ol>
      <li parentName="ol">{`Go to tab `}<em parentName="li">{`Active elements`}</em>{`.`}</li>
      <li parentName="ol">{`Click `}<em parentName="li">{`Add`}</em>{`.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0e58afa7c509a2d30d91c8c950fddccb/1c412/ec-add-element.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAABPklEQVQoz6VSPU/DMBDNj0diYIaJASSGsiIEiNKk0CpdMiCFDkCRGBjYqBJ/np08dG4NaQtSBSc93enZ7/nsc7J/eIx0lOPyJsPVYIjr9A79bIR+FvMSw+2QPD49g8MYDbIG/42kLB9CQeTgvYfzHlLKAGstpFKQUoGI4JxbgTYmZNZFbTKdToNh0zQht20LIUSAJUJdL2oW8hrvY3DNh7JR1IUOu4ZMxgXuqHtINOoi7u9io8NYz+cfEMqAnEf7i0HkVt5wvcMYZC2EJlj/za0b/sRvdKiUQlVVi3cjghQCdV0Hfv3qWmt438A3LTxzbFiWZTCK02IhG7ChMeZrQMyHSS4nzLUOnOtcuEUym738+c+xFf/cg/wNu+kr7t81kl6vh6IokOc5JpPJ1hiNcwxuxwF7J+fYOTrD6UWKT1TcQp2fCHRuAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/0e58afa7c509a2d30d91c8c950fddccb/e93cc/ec-add-element.webp 300w", "/static/0e58afa7c509a2d30d91c8c950fddccb/b0544/ec-add-element.webp 600w", "/static/0e58afa7c509a2d30d91c8c950fddccb/68fc1/ec-add-element.webp 1200w", "/static/0e58afa7c509a2d30d91c8c950fddccb/61c51/ec-add-element.webp 1276w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/0e58afa7c509a2d30d91c8c950fddccb/eed55/ec-add-element.png 300w", "/static/0e58afa7c509a2d30d91c8c950fddccb/7491f/ec-add-element.png 600w", "/static/0e58afa7c509a2d30d91c8c950fddccb/8537d/ec-add-element.png 1200w", "/static/0e58afa7c509a2d30d91c8c950fddccb/1c412/ec-add-element.png 1276w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/0e58afa7c509a2d30d91c8c950fddccb/8537d/ec-add-element.png",
              "alt": "ec-add-element",
              "title": "ec-add-element",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
3. On the left bottom corner of form `}<em parentName="p">{`Add element`}</em>{`, change toggle to `}<em parentName="p">{`on`}</em>{` and click button `}<em parentName="p">{`Accept`}</em>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d65f39def0568bf0d87ed4d6b383c799/ec8bd/ec-add-element-smart-window-fill.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAABdklEQVQoz6WSSU8CQRCF54+bePHqQTx40osL0RvK0oNADAzq2Zs3wyLEwJBZ6H2eqR4GgmhitJMv3ZXqfnlV1d7pxTVY6wG37B53rIVas41as7PL/Yb6F6p+C51uHweHJXiT6RRGKwjBITjHf1av/wRvMBy6QGsNYy20MRBCQEoJpdT6rLXZYEyekxLWWnd2gr0evNFo5AJKFMJhGCKOY0ghEEcR0iRBZi2yzK72DFor8FVFxhi3B0GwLUgXi0VupTbgUkEoA6ntDspk7s2W4Hg8dgElCkEqcT4PMQkTvH+EWMRLCJ2BKwuuc5bKQmj7ncPxjkNyt0xTpNKi8Ewpm2VrimJ+dEiCRJqmSJIEXAhoJZ0wxUpJ10O76mXRQxKXSm8EB4PBehgECUZRlIty7oZD0LTJSX7PQEm5HgpWdQQ05dls/qc/J4q/9xZhr/6K85cFuo/P8MrlMtrtNhhj8H3/11TrDTQYw9lNBfsnVyhdVnBUOsYnrnU5H434xQ0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/d65f39def0568bf0d87ed4d6b383c799/e93cc/ec-add-element-smart-window-fill.webp 300w", "/static/d65f39def0568bf0d87ed4d6b383c799/b0544/ec-add-element-smart-window-fill.webp 600w", "/static/d65f39def0568bf0d87ed4d6b383c799/68fc1/ec-add-element-smart-window-fill.webp 1200w", "/static/d65f39def0568bf0d87ed4d6b383c799/7188c/ec-add-element-smart-window-fill.webp 1306w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/d65f39def0568bf0d87ed4d6b383c799/eed55/ec-add-element-smart-window-fill.png 300w", "/static/d65f39def0568bf0d87ed4d6b383c799/7491f/ec-add-element-smart-window-fill.png 600w", "/static/d65f39def0568bf0d87ed4d6b383c799/8537d/ec-add-element-smart-window-fill.png 1200w", "/static/d65f39def0568bf0d87ed4d6b383c799/ec8bd/ec-add-element-smart-window-fill.png 1306w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/d65f39def0568bf0d87ed4d6b383c799/8537d/ec-add-element-smart-window-fill.png",
              "alt": "ec-add-element-smart-window-fill",
              "title": "ec-add-element-smart-window-fill",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Element type`}</em>{`: Select `}<em parentName="li">{`Active element`}</em>{`. `}</li>
      <li parentName="ul"><em parentName="li">{`Name`}</em>{`: Give a meaningful name.`}</li>
      <li parentName="ul"><em parentName="li">{`Status`}</em>{`: Toggle `}<em parentName="li">{`on`}</em>{` and `}<em parentName="li">{`off`}</em>{`.`}</li>
      <li parentName="ul"><em parentName="li">{`Active placeholder`}</em>{`: Select `}<em parentName="li">{`-new placeholder-`}</em>{`, if you want to create a new one or re-use an existing. `}
        <blockquote parentName="li">
          <p parentName="blockquote">{`Check `}<a parentName="p" {...{
              "href": "/configure-placeholder.md"
            }}><em parentName="a">{`Configure-placeholder`}</em></a>{` for additional information.`}</p>
        </blockquote>
      </li>
    </ul>
    <h2>{`Result`}</h2>
    <p>{`After accepting, the element is added to the campaign.`}</p>
    <h3>{`3. Configure the e-commerce in the skill`}</h3>
    <ol>
      <li parentName="ol">{`On the bottom left corner of Contact Center and Analytics Studio desktop, click icon App (`}<img parentName="li" {...{
          "src": "../images/ccas-icon-app.png",
          "alt": "icon-app"
        }}></img>{`
)`}</li>
    </ol>
    <p><img parentName="p" {...{
        "src": "../images/ccas-desktop.png",
        "alt": "ccas-desktop"
      }}></img>{`
2. Scroll down until you find section `}<em parentName="p">{`Management`}</em>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "../images/ccas-applications.png",
        "alt": "ccas-applications"
      }}></img>{`
3. Select `}<em parentName="p">{`Teams`}</em>{` to open app `}<em parentName="p">{`Teams`}</em>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "../images/ccas-audio-list.png",
        "alt": "new-audio"
      }}></img>{`
4. Click on an existing team or create a new one.
5. Proceed to tab skill and open the skill that you want to configure with e-commerce comtext or create a new skill.
6. Scroll down to `}<em parentName="p">{`Smart windows`}</em>{` and select the context.
7. On the right top corner of form `}<em parentName="p">{`skill`}</em>{`, click button `}<em parentName="p">{`Save`}</em>{`.`}</p>
    <h3>{`4. Configure element by adding widget`}</h3>
    <ol>
      <li parentName="ol">{`On the bottom right of the tab `}<em parentName="li">{`Active elements`}</em>{` in `}<em parentName="li">{`Edit campaign`}</em>{`, click button `}<em parentName="li">{`Add new element`}</em>{`.`}</li>
      <li parentName="ol">{`Scroll down until you find `}<em parentName="li">{`Smart Window`}</em>{` and select.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/386e00f367c3cde77d1d3d66f774a736/1e78a/ec-add-element-list.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "93.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAABYlAAAWJQFJUiTwAAACjklEQVQ4y41UCW7bMBDU//9VtEiRxlcdS74l6qao04nvKYbCKobRAiUwIC1yZ2cvO9Pf7zgdjyirCsfTEff7Hbfbze5yfoR8f8T1egXXdu/Dmb179sfpdLIGsoTgfxZJuXZ+AGcxG+OjrXC+XHG7XnA+n63HNE0RRRHath3w+flpnfBecLlcrBjc79hToTt5RRKFWEcaUaZRVZUFyXa7HTabzbDv93sEQQDf9xHHsX1Dx3zfNA3W6zWcxfw3Um2wDjXi3NgLggYkIEiglBoQhiHKsoQxxu51XaPrOuvU8SavSOMQm9ggL/pLEtKQarTWKIrCGggYvjgmaMNvVuHy7TsKtcEqNjCmRNP0hAyHyrhnWWbVEBLePwm90Q8U0R5eZJBog7IiYWuJttutheSOqkku4VK5gIR9yNNfKLMIflJAJTlUom1PUt1iscByuYTneQNc17Xfeead3NNJTzh+gY4DrOM+nK5trDcqpKo8z60qghVNksQWTPIq+eTZhvw2niAsWuyi3Oavqr9yyFBpTBKSibHszB1hhXSdVepM5wso3UKlGqas+hZo+7Zh/zFvrDSVSkGE6JFwKMp47mGvO3hBCk/lUFmBWJcIAjU0NcHiMAUM/W/EXdtiRcLRdIbM1NjGup+W3KB+aBsBySSnz61DwsPhAG+1gjOZzeCnBcbrCCuV2vGrqwpKhZZIJkVIOSVCKu1DNHUNd7mE8zqaIdENNlEObfoq0ytDo7FUVWaX+SSZEEnIH4cDlgz552iOIG+xClNo8zV6JGPemEdpcDmTWJwIKXNoFU7GI2S5hqtyO8vNQ9vQmEplGqiOeG6dvn/bPofjl28okwBBVg5/XXwk/ffcIkLwDPbhu+viDz/XkQRyDGRKAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/386e00f367c3cde77d1d3d66f774a736/e93cc/ec-add-element-list.webp 300w", "/static/386e00f367c3cde77d1d3d66f774a736/b0544/ec-add-element-list.webp 600w", "/static/386e00f367c3cde77d1d3d66f774a736/68fc1/ec-add-element-list.webp 1200w", "/static/386e00f367c3cde77d1d3d66f774a736/59a8d/ec-add-element-list.webp 1668w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/386e00f367c3cde77d1d3d66f774a736/eed55/ec-add-element-list.png 300w", "/static/386e00f367c3cde77d1d3d66f774a736/7491f/ec-add-element-list.png 600w", "/static/386e00f367c3cde77d1d3d66f774a736/8537d/ec-add-element-list.png 1200w", "/static/386e00f367c3cde77d1d3d66f774a736/1e78a/ec-add-element-list.png 1668w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/386e00f367c3cde77d1d3d66f774a736/8537d/ec-add-element-list.png",
              "alt": "ec-add-element-list",
              "title": "ec-add-element-list",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
3. Fill in the `}<em parentName="p">{`Name`}</em>{`, and `}<em parentName="p">{`Skill`}</em>{` and select `}<em parentName="p">{`Smart Window`}</em>{` as `}<em parentName="p">{`Functionality type`}</em>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "../images/ec-ecommece.png",
        "alt": "ec-ecommece"
      }}></img></p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Name`}</em>{`: It is required and pre-filled with date and time. It is free text but you should give a meaningful name.`}</li>
      <li parentName="ul"><em parentName="li">{`Type of service`}</em>{`: Select `}<em parentName="li">{`chat`}</em>{` or/and `}<em parentName="li">{`call`}</em>{`. This field defines the type of communication channel available in the smart window.`}</li>
      <li parentName="ul"><em parentName="li">{`Functionality type`}</em>{`: Select `}<em parentName="li">{`Smart Window`}</em>{` for the e-commerce widget (extended window) and `}<em parentName="li">{`s
_Standard`}</em>{` for contact only.`}</li>
      <li parentName="ul"><em parentName="li">{`Contact window`}</em>{`: Select `}<em parentName="li">{`chat`}</em>{` or `}<em parentName="li">{`call`}</em>{`.`}</li>
      <li parentName="ul"><em parentName="li">{`Skill`}</em>{`: Select the skill with the e-commerce context defined.`}</li>
      <li parentName="ul"><em parentName="li">{`Show list of operators`}</em>{`: Select the number.`}</li>
      <li parentName="ul"><em parentName="li">{`Global Settings`}</em>{`: Select layout personalization according to client’s layout.`}
        <ul parentName="li">
          <li parentName="ul">{`There are options as `}<em parentName="li">{`Select icon`}</em>{` or `}<em parentName="li">{`Primary color`}</em>{` or `}<em parentName="li">{`Horizontal/Vertical Offset`}</em>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul"><em parentName="li">{`Localization`}</em>{`: For each available location, the text can be personalized. `}</li>
    </ul>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`On the left bottom corner of form `}<em parentName="li">{`Configure element`}</em>{`, click button `}<em parentName="li">{`Accept`}</em>{`.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "31%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA9klEQVQY032PXWuDMBSG/b3rblev/DW72a8YjDJ2OxDabX4nKtMVUdEaY8w7cpidhdIDDydvAs85sdz9AaaUUhBCYBxHQkqJpmnoPM/zVdSs8RPHmEdBjruHLSx3/0FhUgrDMJCkqip0XYe2bdH3PUnNMPNu+sIoJ3x7HpQ4kWOzXQnNxKXXdU2ysiyR5zmyLCM45+dcFAXkpHBkHJMQf0LbCP+/bGRa63NOkgS+7yMIAkRRRN3kMAzBGKPN17WxbViHLw+3ahlwkVd3UgPe8YRKaNwb4cvuldZPGAPn6QVpasiukjCOzyDGm+vBedrh8fkdtuPgFx9HwFQhRC6qAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/b5fcd26c957b093eec19cf2029dcd5f4/e93cc/ec-smart-window-configure-element-3.webp 300w", "/static/b5fcd26c957b093eec19cf2029dcd5f4/b0544/ec-smart-window-configure-element-3.webp 600w", "/static/b5fcd26c957b093eec19cf2029dcd5f4/68fc1/ec-smart-window-configure-element-3.webp 1200w", "/static/b5fcd26c957b093eec19cf2029dcd5f4/8437c/ec-smart-window-configure-element-3.webp 1714w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/b5fcd26c957b093eec19cf2029dcd5f4/eed55/ec-smart-window-configure-element-3.png 300w", "/static/b5fcd26c957b093eec19cf2029dcd5f4/7491f/ec-smart-window-configure-element-3.png 600w", "/static/b5fcd26c957b093eec19cf2029dcd5f4/8537d/ec-smart-window-configure-element-3.png 1200w", "/static/b5fcd26c957b093eec19cf2029dcd5f4/013fe/ec-smart-window-configure-element-3.png 1714w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/b5fcd26c957b093eec19cf2029dcd5f4/8537d/ec-smart-window-configure-element-3.png",
            "alt": "ec-smart-window-configure-element-3",
            "title": "ec-smart-window-configure-element-3",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
5. Configure placeholder (as explained in `}<a parentName="p" {...{
        "href": "/configure-placeholder.md"
      }}><em parentName="a">{`Configure-placeholder`}</em></a>{`).
6. Define the segmentation rules on `}<em parentName="p">{`Segmentation`}</em>{` tab.
7. Click button `}<em parentName="p">{`Save campaign`}</em>{`. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4a2eaeb95138bc955931f142481788c1/15103/ec-save-campaign.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "34.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAABAUlEQVQoz6WQS0/CQBSF5/9vXRofEexgWLpRkpJo7HRaujFxZUFYaKJCsRQIVDrzmfKwMWFh9CRf7pl7T+5kRqjuG/FrSvye0R1O6W15Gk3pjzZ15/tJxSApcxMGw5Tg8ZmDm5hDr4fIFjl7ZVYbfqFitSKZ5YznOWI8WwKWoigwxoC1GFOQTjIWy3zd283KusFUGIPFbldbRJrbH7fNDBj+LhHePxAGAVoHKN/n1lPcKR/f1+uz8vW311qvCcOQKIoIOx08pWi327iuSyeKEM3LK46OTzg9q+E4Dg0pkdJBOvspM7XzMrPNSclFo0G9Xue61UK8fMz5LP7zyErl530BPy0EK+WLBMoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/4a2eaeb95138bc955931f142481788c1/e93cc/ec-save-campaign.webp 300w", "/static/4a2eaeb95138bc955931f142481788c1/b0544/ec-save-campaign.webp 600w", "/static/4a2eaeb95138bc955931f142481788c1/68fc1/ec-save-campaign.webp 1200w", "/static/4a2eaeb95138bc955931f142481788c1/a2303/ec-save-campaign.webp 1800w", "/static/4a2eaeb95138bc955931f142481788c1/4293a/ec-save-campaign.webp 2400w", "/static/4a2eaeb95138bc955931f142481788c1/55654/ec-save-campaign.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/4a2eaeb95138bc955931f142481788c1/eed55/ec-save-campaign.png 300w", "/static/4a2eaeb95138bc955931f142481788c1/7491f/ec-save-campaign.png 600w", "/static/4a2eaeb95138bc955931f142481788c1/8537d/ec-save-campaign.png 1200w", "/static/4a2eaeb95138bc955931f142481788c1/d2cc9/ec-save-campaign.png 1800w", "/static/4a2eaeb95138bc955931f142481788c1/00711/ec-save-campaign.png 2400w", "/static/4a2eaeb95138bc955931f142481788c1/15103/ec-save-campaign.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/4a2eaeb95138bc955931f142481788c1/8537d/ec-save-campaign.png",
              "alt": "ec-save-campaign",
              "title": "ec-save-campaign",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
8. Activate the campaign in `}<em parentName="p">{`Status`}</em>{` toggle.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/907879a2040e472c4f70165a7cf80724/1a70e/ec-online-smartwindow.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAABkElEQVQoz53Pz04TURTH8XkNEzEmBKJRN+7ckrjgRXwAl4aI1qAQMLLDF2DXUGbS6czQ+RvBWigSkU6BiCC6MO3QlvYSQ2far5lpMenCxHiST3LO7yT35EojUyrjKY3RZyrXnshcn5K58VTm5rTM6HOZsZTM+AuZWzMKt18q3HmlcHdW4d6cwv35LA8WNR6+NZlcMph4k0F6tLzJp9OA90dVCl9rfcc1iifBQC2x+a1v68ppvKvy8eQnK6UjZowyi46PtJD3+Xt1+ZeKwoj6RYfGRQdpdq2chL8uO3TCkDCMiLpdzlttGi2RzHE+LErEuzCK6PV6yRtxL722K0PXQuCS/6v4P9J0pkCpsE5ON9B0HVXTyeZ0tME8zEA3DPKmiWlZrOXzZFWVzOoq6XSa4vY2Ukr+QMExUXM5PMdmw3VYdx3euXafc8X509uWjWcPMtdlw/PwLIuK7yM9zpTY+XFG8Xud3apgt9bmcyDYCwTlM4FfF1RiDcF+U3DQFFSabfYbLQ7PBYetvi9twU414DeCcym9aE9jIQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/907879a2040e472c4f70165a7cf80724/e93cc/ec-online-smartwindow.webp 300w", "/static/907879a2040e472c4f70165a7cf80724/b0544/ec-online-smartwindow.webp 600w", "/static/907879a2040e472c4f70165a7cf80724/68fc1/ec-online-smartwindow.webp 1200w", "/static/907879a2040e472c4f70165a7cf80724/a2303/ec-online-smartwindow.webp 1800w", "/static/907879a2040e472c4f70165a7cf80724/4293a/ec-online-smartwindow.webp 2400w", "/static/907879a2040e472c4f70165a7cf80724/4f10c/ec-online-smartwindow.webp 2872w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/907879a2040e472c4f70165a7cf80724/eed55/ec-online-smartwindow.png 300w", "/static/907879a2040e472c4f70165a7cf80724/7491f/ec-online-smartwindow.png 600w", "/static/907879a2040e472c4f70165a7cf80724/8537d/ec-online-smartwindow.png 1200w", "/static/907879a2040e472c4f70165a7cf80724/d2cc9/ec-online-smartwindow.png 1800w", "/static/907879a2040e472c4f70165a7cf80724/00711/ec-online-smartwindow.png 2400w", "/static/907879a2040e472c4f70165a7cf80724/1a70e/ec-online-smartwindow.png 2872w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/907879a2040e472c4f70165a7cf80724/8537d/ec-online-smartwindow.png",
              "alt": "ec-online-smartwindow",
              "title": "ec-online-smartwindow",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote">{`Notes:`}</p>
      <ul parentName="blockquote">
        <li parentName="ul">{`By clicking on the eye, it is possible to preview the element.`}</li>
        <li parentName="ul">{`Make sure the element is activated by checking the element `}<em parentName="li">{`Status`}</em>{` toggle.`}</li>
        <li parentName="ul">{`The definition of segmentation rules is not mandatory, but it is highly recommended to configure it to prevent the widget from appearing on unwanted pages. There are various rules available, such as URL pages or localization. They can be general (`}<em parentName="li">{`Segmentation`}</em>{` tab or in the widget by clicking the spanner).`}</li>
      </ul>
    </blockquote>
    <h2>{`Result`}</h2>
    <p>{`After saving the campaign, the smart window is available according to the rules defined in the segmentation.`}</p>
    <p><img parentName="p" {...{
        "src": "../images/ccas-new-audio-result.png",
        "alt": "new-audio-uploaded"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      